import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  undo: {
    id: "undo",
    eventId: AnalyticsEvent.MarkUpToolUndo,
  },
  redo: {
    id: "redo",
    eventId: AnalyticsEvent.MarkUpToolRedo,
  },
  color: {
    id: "color",
    eventId: AnalyticsEvent.MarkUpToolSelectColor,
  },
  brush: {
    id: "brush",
    eventId: AnalyticsEvent.MarkUpToolSelectBrush,
    params: { tool: "brush" },
  },
  pixelize: {
    id: "pixelize",
    eventId: AnalyticsEvent.MarkUpToolSelectBlur,
    params: { tool: "blur" },
  },
  arrow: {
    id: "arrow",
    eventId: AnalyticsEvent.MarkUpToolSelectArrow,
    params: { tool: "arrow" },
  },
  text: {
    id: "text",
    eventId: AnalyticsEvent.MarkUpToolSelectText,
    params: { tool: "text" },
  },
  selection: {
    id: "selection",
    eventId: AnalyticsEvent.MarkUpToolSelectTool,
    params: { tool: "selection" },
  },
  ellipse: {
    id: "ellipse",
    eventId: AnalyticsEvent.MarkUpToolSelectCircle,
    params: { tool: "ellipse" },
  },
  rect: {
    id: "rect",
    eventId: AnalyticsEvent.MarkUpToolSelectRectangle,
    params: { tool: "rect" },
  },
  line: {
    id: "line",
    eventId: AnalyticsEvent.MarkUpToolSelectLine,
    params: { tool: "line" },
  },
  crop: {
    id: "crop",
    eventId: AnalyticsEvent.MarkUpToolSelectCrop,
    params: { tool: "crop" },
  },
}
